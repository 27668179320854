import { GatsbySeo } from 'gatsby-plugin-next-seo'
import RenderPageSections from 'src/components/cms/RenderedPageSections'
import { getCMSPageByContentType } from 'src/utils/getCMSPageDataByContentType'
import { getShelfServerSideData } from 'src/utils/recommendations/getShelfServerSideData'

interface CmsData {
  sections: Array<{
    data: unknown
    id: string
    name: string
  }>
  status: string
}

interface Props {
  slug: string
  serverData: {
    cmsData?: CmsData
  }
}

export default function CampaignPage(props: Props) {
  const { serverData } = props
  const { cmsData } = serverData

  if (serverData === null) {
    return null
  }

  return (
    <>
      <GatsbySeo
        title=""
        description=""
        titleTemplate=""
        language=""
        canonical=""
        openGraph={{
          type: 'website',
          url: '',
          title: '',
          description: '',
        }}
      />

      <RenderPageSections sections={cmsData?.sections} />
    </>
  )
}

export const getServerData = async ({
  query,
  params: { slug },
}: {
  query: Record<string, string>
  params: Record<string, string>
}) => {
  const cmsData = await getCMSPageByContentType({
    slug,
    contentType: 'campanha',
    pageId: query?.pageId ?? '',
    versionId: query?.versionId ?? '',
  })

  if (cmsData.props?.cmsData.sections) {
    const newSections = await getShelfServerSideData({
      sections: cmsData.props?.cmsData.sections,
    })

    cmsData.props.cmsData.sections = newSections
  }

  return cmsData
}
